import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import CardList from './CardList'
import Card from './Card'

const IsFeaturedList = (props, { data, pageContext }) => {
  console.log('props', props)
  // console.log('pageContext', pageContext)
  // const posts = data.allContentfulPost.edges
  // const { basePath } = pageContext
  // let ogImage

  // try {
  //   ogImage = posts[0].node.heroImage.ogimg.src
  // } catch (error) {
  //   ogImage = null
  // }

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulPost(
            sort: { fields: [publishDate], order: DESC }
            filter: { isFeatured: { eq: true } }
          ) {
            edges {
              node {
                title
                id
                slug
                publishDate(formatString: "MMMM DD, YYYY")
                heroImage {
                  title
                  fluid(maxWidth: 1800) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                  ogimg: resize(width: 1800) {
                    src
                  }
                }
                body {
                  childMarkdownRemark {
                    timeToRead
                    html
                    excerpt(pruneLength: 80)
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section
          className="isFeaturedList"
          style={{
            maxWidth: '1050px',
            margin: '0 auto',
            padding: '0 1.5em 2em',
          }}
        >
          <h2
            style={{
              fontSize: '1.4em',
              fontWeight: 'bold',
              margin: '1em auto',
              textAlign: 'center',
            }}
          >
            人気の記事
          </h2>
          <CardList>
            {data.allContentfulPost.edges.map(({ node: post }) => (
              <Card key={post.id} {...post} basePath={'/'} />
            ))}
          </CardList>
        </section>
      )}
    />
  )
}

export default IsFeaturedList
