import React, { useRef, useState } from 'react'
import { Link, NavLink } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons'
import {
  faLanguage,
  faStarHalfAlt,
  faPenAlt,
  faRunning,
  faHeart,
  faAddressCard,
  faBaby,
} from '@fortawesome/free-solid-svg-icons'

const Header = styled.header`
  /* background: ${props => props.theme.colors.primary}; */
  background:#fff;
  width: 100%;
`

const Drawer = styled.div`
  position: fixed;
  top: -100vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  transition: all 0.3s;
  .drawer__bg {
    position: fixed;
    background: rgba(255, 255, 255, 0);

    width: 100vw;
    height: 100vh;
    z-index: 10100;
  }
  .drawer__nav {
    background: #111;
    position: fixed;
    width: 100vw;
    z-index: 10200;
    padding: 100px 0px 0px;
    li {
      a {
        text-decoration: none;
        color: #fff;
        display: flex;
        /* text-align: right; */
        padding: 16px 26px;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        &.active {
          background: #ddd;
          color: #222;
        }
      }
    }
  }
  &.active {
    top: 0;
    li {
      a {
        span {
          opacity: 0;
          &:nth-of-type(1) {
            animation: appearFromL 0.6s cubic-bezier(0, 1, 0, 1) 0.4s 1 normal
              forwards;
          }
          &:nth-of-type(2) {
            animation: appearFromR 0.6s cubic-bezier(0, 1, 0, 1) 0.4s 1 normal
              forwards;
          }
        }
      }
    }
    @keyframes appearFromL {
      0% {
        opacity: 0;
        transform: translateX(-90px);
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes appearFromR {
      0% {
        opacity: 0;
        transform: translateX(90px);
      }
      100% {
        opacity: 1;
      }
    }
  }
`
const Nav = styled.nav`
  position:fixed;
  background:#fff;
  width: 100%;
  /* max-width: ${props => props.theme.sizes.maxWidth}; */
  margin: 0 auto;
  padding: 1.5em 1.5em;
  z-index:20000;
  #logo{
    fill:#111;
    display:block;
    cursor: pointer;
    width:100px;
  }
  #logo__big,#logo__or,#logo__small,#logo__0,#logo__l1,#logo__l2,#logo__o__outer {
    opacity: 0;
    animation-name:appearFromTop;
    animation-delay:0.2s;
    animation-timing-function:cubic-bezier(0, 1, 0, 1);
    animation-iteration-count:1;
    animation-duration:0.8s;
    animation-direction:normal;
    animation-fill-mode:forwards;
    transform-origin:center center;
    position:relative;
    display:inline-block;
  }
  #logo__or{
    animation-name:appearFromLeft;
    animation-delay:0.3s;
  }
  #logo__small{
    animation-name:appearFromBottom;
    animation-delay:0.4s;
  }
  #logo__0{
    animation-name:appearFromLeft;
    animation-delay:0.5s;
  }
  #logo__l1{
    animation-name:appearFromTop;
    animation-delay:0.6s;
  }
  #logo__l2{
    animation-name:appearFromBottom;
    animation-delay:0.7s;
  }
  #logo__o{
    position: relative;
    display: inline-block;
  }
  #logo__o__outer {
    position: absolute;
    bottom:0;
    left:86px;
    width:13px;
    animation-name:appearFromRight;
    animation-delay:0.9s;
    animation-duration:3.0s;
    transform-origin:center center;
    animation-timing-function:cubic-bezier(0, 0.7, 0, 0.7);
  }
  @keyframes appearFromLeft {
    0%{
      opacity: 0;
      transform:translateX(-90px)
    }
    100%{
      opacity: 1;
    }
  }
  @keyframes appearFromTop {
    0%{
      opacity: 0;
      transform:translateY(-90px)
    }
    100%{
      opacity: 1;
    }
  }
  @keyframes appearFromRight {
    0%{
      opacity: 0;
      transform:rotate(720deg) translateX(10px) translateY(-10px)
    }
    20%{
      opacity: 1;
    }
    100%{
      opacity: 1;
      transform:rotate(0deg) translateX(0px) translateY(0px)
    }
  }
  @keyframes appearFromBottom {
    0%{
      opacity: 0;
      transform:translateY(90px)
    }
    100%{
      opacity: 1;
    }
  }
  /* transition:all 0.3s; */
  &.active{
    background:transparent;
    #logo,#logo__o__outer{
    fill:#fff;
  }
  }


  ul {
    display: flex;
    justify-content: space-between;
    align-items:center;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    &:first-of-type {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }
  .btnMenu {
    display:flex;
    width:30px;
    height:30px;
    cursor: pointer;
    align-items:center;
    justify-content:center;
    >span,>span:before,>span:after{
      transition:all 0.3s
    }
    >span{
      height:4px;
      width:24px;
      border-radius:4px;
      background:#333;
    }
    >span:before,>span:after{
      content:'';
      position:absolute;
      height:4px;
      width:24px;
      border-radius:4px;
      background:#333;
    }
    >span:before {
      transform:translateY(8px);
    }
    >span:after {
      transform:translateY(-8px);
    }

    &.active{
      >span,>span:before,>span:after{
        background:#fff;
      }
      >span{
        transform:translateX(-30px);
        background:transparent;
      }
      >span:before {
        background:#fff;
        transform:translateX(30px) rotate(225deg);
      }
      >span:after {
        background:#fff;
        transform:translateX(30px) rotate(-225deg);
      }

    }
  }

  a {
    text-decoration: none;
    color: DarkGray;
    font-weight: 600;
    transition: all 0.2s;
    /* border-bottom: 2px solid ${props => props.theme.colors.text}; */
    &:hover {
      color: #111;
    }
  }
`

const activeLinkStyle = {
  color: '#f11',
}

const Menu = () => {
  const { menuLinks } = useSiteMetadata()
  const [menuState, setMenuState] = useState(false)
  const $btnMenu = useRef()
  const $drawer = useRef()
  const $nav = useRef()
  const toggleDrawer = () => {
    if (!menuState) {
      setMenuState(true)
      $btnMenu.current.classList.add('active')
      $drawer.current.classList.add('active')
      $nav.current.classList.add('active')
      console.log('menuLinks', menuLinks)
    } else {
      setMenuState(false)
      $btnMenu.current.classList.remove('active')
      $drawer.current.classList.remove('active')
      $nav.current.classList.remove('active')
    }
  }

  return (
    <Header>
      <Nav ref={$nav}>
        <ul>
          {/* {menuLinks.map(link => ( */}
          <li>
            <Link to={'/'} activeStyle={activeLinkStyle}>
              <svg
                id="logo"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 360.14 90.17"
              >
                <g id="logo__big">
                  <path
                    d="M-.14,42V.54H14.75q8,0,12.17,2.93A9.76,9.76,0,0,1,31.09,12a9.85,9.85,0,0,1-1.48,5.5,8.6,8.6,0,0,1-4.35,3.36,8.74,8.74,0,0,1,5,3.19A9.23,9.23,0,0,1,32,29.72q0,6.09-3.86,9.15T16.69,42Zm10-24.17h5.21c2.19,0,3.74-.42,4.67-1.2a4.25,4.25,0,0,0,1.4-3.45,4.46,4.46,0,0,0-1.51-3.79Q18.1,8.22,14.75,8.23H9.85Zm0,6.55V34.3H16.4a6.26,6.26,0,0,0,4.16-1.23A4.34,4.34,0,0,0,22,29.58c0-3.46-1.72-5.19-5.15-5.21Z"
                    transform="translate(0.14 0.06)"
                  />
                  <path
                    d="M48.12,42h-10V.54h10Z"
                    transform="translate(0.14 0.06)"
                  />
                  <path
                    d="M89,36.87A16.14,16.14,0,0,1,82.25,41a28.91,28.91,0,0,1-9.73,1.57q-8.14,0-13-5T54.3,23.71l0-3.58A24.75,24.75,0,0,1,56.43,9.44a16.13,16.13,0,0,1,6.19-7A17.56,17.56,0,0,1,72-.06q7.74,0,12,3.55t5,10.57H79.35a7.86,7.86,0,0,0-2.22-5,7.15,7.15,0,0,0-4.84-1.48,6.51,6.51,0,0,0-5.81,3.19Q64.43,14,64.4,19.93v2.5q0,6.21,2.12,9.33c1.42,2.07,3.64,3.11,6.68,3.11q3.9,0,5.81-1.73V26.7h-7V19.84H89Z"
                    transform="translate(0.14 0.06)"
                  />
                </g>
                <g id="logo__0">
                  <path
                    d="M185.94,55.31A52.12,52.12,0,0,1,184,70.13,30.91,30.91,0,0,1,178.49,81a23.35,23.35,0,0,1-8.84,6.71,32.46,32.46,0,0,1-23.9,0A23.8,23.8,0,0,1,136.82,81a30.7,30.7,0,0,1-5.62-10.88,51.37,51.37,0,0,1-1.95-14.82V34.81A52.12,52.12,0,0,1,131.17,20a30.94,30.94,0,0,1,5.56-10.91,23.7,23.7,0,0,1,8.9-6.77,32.14,32.14,0,0,1,23.93,0,23.7,23.7,0,0,1,8.9,6.77A30.94,30.94,0,0,1,184,20a52.12,52.12,0,0,1,1.92,14.82Zm-45.51-2.22,34.09-26.16q-1-8.71-5.08-13.25T157.57,9.14q-8.79,0-13,5.92t-4.18,17.28Zm34.33-15.51-34,26q1,8.48,5.23,12.92T157.69,81q8.84,0,12.95-6t4.12-17.31Z"
                    transform="translate(0.14 0.06)"
                  />
                </g>
                <g id="logo__l1">
                  <path
                    d="M230.72,90H220V0h10.72Z"
                    transform="translate(0.14 0.06)"
                  />
                </g>
                <g id="logo__l2">
                  <path
                    d="M276,90H265.28V0H276Z"
                    transform="translate(0.14 0.06)"
                  />
                </g>
                <g id="logo__or">
                  <path
                    d="M17.22,56.5a11.57,11.57,0,0,1-1.07,5.08,7.88,7.88,0,0,1-3,3.4,8.49,8.49,0,0,1-4.47,1.19A8.49,8.49,0,0,1,4.22,65a8,8,0,0,1-3-3.29A11.21,11.21,0,0,1,.06,56.81V55.7a11.67,11.67,0,0,1,1.05-5.08,7.9,7.9,0,0,1,3-3.4A8.51,8.51,0,0,1,8.64,46a8.38,8.38,0,0,1,4.44,1.19,8,8,0,0,1,3,3.37,11.34,11.34,0,0,1,1.1,5Zm-4.81-.82a8.26,8.26,0,0,0-1-4.44,3.13,3.13,0,0,0-2.79-1.52c-2.37,0-3.63,1.78-3.76,5.34V56.5a8.45,8.45,0,0,0,1,4.43,3.14,3.14,0,0,0,2.84,1.55A3.11,3.11,0,0,0,11.43,61a8.36,8.36,0,0,0,1-4.38Z"
                    transform="translate(0.14 0.06)"
                  />
                  <path
                    d="M26.94,59H24.38V65.9H19.66V46.29h7.71a8.63,8.63,0,0,1,5.46,1.55,5.25,5.25,0,0,1,2,4.37A6.4,6.4,0,0,1,34,55.6a5.81,5.81,0,0,1-2.6,2.18l4.1,7.92v.2H30.41Zm-2.56-3.64h3a2.69,2.69,0,0,0,2-.71,3.22,3.22,0,0,0,0-4,2.65,2.65,0,0,0-2-.72h-3Z"
                    transform="translate(0.14 0.06)"
                  />
                </g>
                <g id="logo__small">
                  <path
                    d="M10.72,84.63A1.94,1.94,0,0,0,10,83a9,9,0,0,0-2.58-1.2,24.27,24.27,0,0,1-3-1.2Q.58,78.74.58,75.47a4.64,4.64,0,0,1,1-2.88,6.17,6.17,0,0,1,2.69-1.94A10.52,10.52,0,0,1,8.13,70a9.19,9.19,0,0,1,3.79.75,6,6,0,0,1,2.6,2.15,5.61,5.61,0,0,1,.93,3.19H10.74A2.37,2.37,0,0,0,10,74.19a2.84,2.84,0,0,0-2-.66,3.17,3.17,0,0,0-2,.56,1.71,1.71,0,0,0-.74,1.42,1.74,1.74,0,0,0,.81,1.37,10.4,10.4,0,0,0,2.84,1.26,17.73,17.73,0,0,1,3.34,1.41,5.61,5.61,0,0,1,3.18,5,4.78,4.78,0,0,1-1.94,4,8.6,8.6,0,0,1-5.32,1.47,10.5,10.5,0,0,1-4.31-.86A6.5,6.5,0,0,1,1,86.91a6.13,6.13,0,0,1-1-3.43H4.73a3,3,0,0,0,.82,2.33,3.87,3.87,0,0,0,2.64.74,3.08,3.08,0,0,0,1.85-.5A1.65,1.65,0,0,0,10.72,84.63Z"
                    transform="translate(0.14 0.06)"
                  />
                  <path
                    d="M24.09,70.23l4.38,13.64,4.36-13.64h6.22V89.84H34.31V85.26l.46-9.38L30,89.84H26.9l-4.75-14,.46,9.39v4.58H17.88V70.23Z"
                    transform="translate(0.14 0.06)"
                  />
                  <path
                    d="M53.44,86.18H47l-1.13,3.66h-5L48,70.23h4.44l7.25,19.61H54.58Zm-5.35-3.65h4.22l-2.12-6.81Z"
                    transform="translate(0.14 0.06)"
                  />
                  <path
                    d="M65.82,86.2h8.23v3.64h-13V70.23h4.73Z"
                    transform="translate(0.14 0.06)"
                  />
                  <path
                    d="M80.77,86.2H89v3.64H76V70.23h4.73Z"
                    transform="translate(0.14 0.06)"
                  />
                </g>
                {/* <g id="logo__o">
                  <g id="logo__o__inner">
                    <path
                      d="M337,44a23,23,0,1,0,23,23A23,23,0,0,0,337,44Zm0,37a14,14,0,1,1,14-14A14,14,0,0,1,337,81Z"
                      transform="translate(0.14 0.06)"
                    />
                    <circle cx="337.64" cy="57.56" r="1.5" />
                  </g>
                </g> */}
              </svg>
              <svg
                id="logo__o__outer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 46 46"
              >
                <g id="logo__o">
                  <g id="logo__o__inner">
                    <path d="M23,0A23,23,0,1,0,46,23,23,23,0,0,0,23,0Zm0,37A14,14,0,1,1,37,23,14,14,0,0,1,23,37Z" />
                    <circle cx="23.5" cy="13.5" r="1.5" />
                  </g>
                </g>
              </svg>
            </Link>
          </li>
          <li>
            <a href="https://twitter.com/0llo_com" target="_blank">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/0llocom" target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </li>
          <li className="btnMenu" ref={$btnMenu} onClick={toggleDrawer}>
            <span></span>
          </li>
          {/* ))} */}
        </ul>
      </Nav>
      <Drawer className="drawer" ref={$drawer}>
        <div className="drawer__bg" onClick={toggleDrawer}></div>
        <ul className="drawer__nav">
          {/* {menuLinks.map(link => (
            <li key={link.name}>
              <Link to={`${link.slug}`}>
                <span>
                  <FontAwesomeIcon icon={link.faLanguage} />
                </span>
                <span>{link.name}</span>
              </Link>
            </li>
          ))} */}
          <li>
            <Link activeClassName="active" to={`/tag/english/`}>
              <span>
                <FontAwesomeIcon icon={faLanguage} />
              </span>
              <span>英語</span>
            </Link>
            <Link activeClassName="active" to={`/tag/review/`}>
              <span>
                <FontAwesomeIcon icon={faStarHalfAlt} />
              </span>
              <span>レビュー</span>
            </Link>
            <Link activeClassName="active" to={`/tag/essay/`}>
              <span>
                <FontAwesomeIcon icon={faPenAlt} />
              </span>
              <span>雑記</span>
            </Link>
            <Link activeClassName="active" to={`/tag/life/`}>
              <span>
                <FontAwesomeIcon icon={faHeart} />
              </span>
              <span>生活</span>
            </Link>
            <Link activeClassName="active" to={`/tag/childcare/`}>
              <span>
                <FontAwesomeIcon icon={faBaby} />
              </span>
              <span>育児</span>
            </Link>
            <Link activeClassName="active" to={`/tag/sport/`}>
              <span>
                <FontAwesomeIcon icon={faRunning} />
              </span>
              <span>スポーツ</span>
            </Link>
            <Link activeClassName="active" to={`/about/`}>
              <span>
                <FontAwesomeIcon icon={faAddressCard} />
              </span>
              <span>このサイトについて</span>
            </Link>
          </li>
        </ul>
      </Drawer>
    </Header>
  )
}

export default Menu
