import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  margin: 0 auto 1em;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  line-height: 1.8em;
  span {
    margin: 0 0.5rem;
  }
`

const Date = styled.p`
  display: inline-block;
`

const ReadingTime = styled.p`
  display: inline-block;
`

const PostDetails = props => {
  const lastUpDate = () => {
    if (props.lastUpDate) {
      return (
        <>
          <Date>更新日📅 {props.lastUpDate}</Date>
          <span>•</span>
        </>
      )
    }
  }
  return (
    <Wrapper>
      {lastUpDate()}
      <Date>記事投稿日📅 {props.date}</Date>
      <span>•</span>
      <ReadingTime>{`⏱️${props.timeToRead} 分で読めます `}</ReadingTime>
    </Wrapper>
  )
}

export default PostDetails
