import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import SEO from '../components/SEO'
import styled from '@emotion/styled'
import IsFeaturedList from './../components/IsFeaturedList'
import PostShare from './../components/PostShare'
// import ReviewSchema from './../components/ReviewSchema'
// import { ProductJsonLd } from 'gatsby-plugin-next-seo'

const PostTemplate = ({ data, pageContext }) => {
  const {
    slug,
    title,
    metaDescription,
    heroImage,
    body,
    reviewItem,
    reviewScore,
    author,
    publishDate,
    updatedAt,
    tags,
    isIndex,
  } = data.contentfulPost
  const reviewData =
    reviewItem && reviewScore && author
      ? {
          reviewItem,
          reviewScore,
          author,
          publishDate,
        }
      : null

  // const ReviewSchemaDom = reviewItem ? <ReviewSchema /> : null
  const url = `https://0llo.com/${slug}/`
  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext
  console.log('reviewData', reviewData)

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  const Title = styled.h1`
    font-size: 2em;
    text-transform: capitalize;
    font-weight: 600;
    width: 100%;
    max-width: ${props => props.theme.sizes.maxWidthCentered};
    margin: 0 auto 20px;
    padding: 0rem;
    line-height: 1.5em;
    text-align: left;
    color: #222;
  `

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
        reviewData={reviewData}
        isIndex={isIndex}
      />
      {/* <ReviewSchema
        author={author}
        reviewBody={reviewBody}
        reviewScore={reviewScore}
        reviewItem={reviewItem}
      /> */}
      <Hero title={title} image={heroImage} height={'50vh'} />
      <Container>
        <Title>{title}</Title>
        {tags && <TagList tags={tags} basePath={basePath} />}
        <PostDetails
          date={publishDate}
          lastUpDate={updatedAt}
          timeToRead={body.childMarkdownRemark.timeToRead}
        />
        <PostShare title={title} url={url} />
        <PageBody body={body} />
      </Container>
      <PostLinks previous={previous} next={next} basePath={basePath} />
      <IsFeaturedList />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      reviewItem
      reviewBody
      reviewScore
      author
      isIndex
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "MMMM DD, YYYY")
      updatedAtISO: updatedAt(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(format: HTML, pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
