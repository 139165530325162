import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ title, description, image, reviewData, isIndex }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            siteUrl
            # reviewItem
          }
        }
      }
    `
  )

  const defaultImage = site.siteMetadata.siteUrl + site.siteMetadata.image
  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || defaultImage
  const reviewDataJSON = () => {
    if (reviewData) {
      let data = {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        name: reviewData.reviewItem,
        image: image,
        description: metaDescription,
        dateCreated: reviewData.publishDate,
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: `${Number(reviewData.reviewScore) / 20}`,
          bestRating: '5',
        },
      }
      return JSON.stringify(data)
    } else {
      return null
    }
  }
  let noIndexMetaTag = null
  if (isIndex === false) {
    noIndexMetaTag = <meta name="robots" content="noindex" />
  }
  // const ReviewSchemaDom = site.siteMetadata.reviewItem ? <ReviewSchema /> : null

  return (
    <Helmet
      htmlAttributes={{
        lang: `ja`,
      }}
      title={title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* General tags */}
      <meta name="image" content={image} />
      <meta name="description" content={metaDescription} />
      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:description" content={metaDescription} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={metaImage} />
      <meta name="twitter:description" content={metaDescription} />
      <script className="structured-data-list" type="application/ld+json">
        {reviewDataJSON()}
      </script>
      {/* <ReviewSchema /> */}
      {noIndexMetaTag}
    </Helmet>
  )
}

export default SEO
