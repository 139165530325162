import React from 'react'

export default function PostShare({ url, title }) {
  return (
    <section className="postShare">
      <div>
        <a
          className="HatenaShareButton"
          href={
            'http://b.hatena.ne.jp/add?mode=confirm&url=' +
            url +
            '&title=' +
            title
          }
          target="_blank"
          rel="nofollow"
        ></a>
      </div>
    </section>
  )
}
